import {
  Intercom,
  shutdown as shutdownIntercom,
} from '@intercom/messenger-js-sdk'
import { storeToRefs } from 'pinia'

import { useGetProfilePicture } from '~/composables/useGetProfilePicture'

import { useUserStore } from '~/stores/user'
import { useUserAgencyStore } from '~/stores/userAgency'
import { useUserBandStore } from '~/stores/userBand'
import { useUserBandSetStore } from '~/stores/userBandSet'
import { useUserInfluencerStore } from '~/stores/userInfluencer'

function intercomGenId(input: string) {
  let output = ''

  for (let i = 0; i < input.length; i++) output += input[i].charCodeAt(0)

  return output
}

export function useIntercomSetup() {
  const {
    first_payment_date: USER_FIRST_PAYMENT_DATE,
    KIND: USER_KIND,
    IS_LOGGED_IN,
    first_name: USER_FIRST_NAME,
    email: USER_EMAIL,
    id: USER_ID,
    submissions: USER_SUBMISSIONS,
  } = storeToRefs(useUserStore())
  const { kind: AGENCY_KIND } = storeToRefs(useUserAgencyStore())
  const { list: BAND_LIST } = storeToRefs(useUserBandSetStore())
  const { BAND_DATA } = storeToRefs(useUserBandStore())
  const { id: INFLUENCER_ID, entity: INFLUENCER_ENTITY } = storeToRefs(
    useUserInfluencerStore(),
  )

  const { getBandProfilePictureForId, getInfProfilePictureForId } =
    useGetProfilePicture()

  const companies = computed(() => {
    if (!IS_LOGGED_IN.value) return []

    if (USER_KIND.value === 'band') {
      return BAND_LIST.value.map((band) => ({
        type: 'company',
        company_id: intercomGenId(band.name) + band.id,
        name: band.name,
        industry: AGENCY_KIND.value,
        first_submission_date:
          band.first_submission_date === null
            ? null
            : new Date(band.first_submission_date).getTime() / 1000,
        latest_submission_date:
          band.latest_submission_date === null
            ? null
            : new Date(band.latest_submission_date).getTime() / 1000,
      }))
    }

    return [
      {
        type: 'company',
        company_id:
          intercomGenId(INFLUENCER_ENTITY.value) + INFLUENCER_ID.value,
        name: INFLUENCER_ENTITY.value,
        industry: 'influencer',
      },
    ]
  })

  function getIntercomSettings() {
    if (!IS_LOGGED_IN.value) return {}

    const submissions = USER_SUBMISSIONS.value.reduce((a, b) => {
      return a + b
    }, 0)

    return {
      name: USER_FIRST_NAME.value,
      email: USER_EMAIL.value,
      user_id: USER_ID.value.toString(),
      percent_1: USER_ID.value % 100 < 1,
      percent_2: USER_ID.value % 100 < 2,
      percent_5: USER_ID.value % 100 < 5,
      percent_10: USER_ID.value % 100 < 10,
      percent_25: USER_ID.value % 100 < 25,
      percent_50: USER_ID.value % 100 < 50,
      percent_75: USER_ID.value % 100 < 75,
      percent_100: USER_ID.value % 100 <= 100,
      avatar: {
        type: 'avatar',
        image_url:
          (BAND_DATA.value
            ? getBandProfilePictureForId(BAND_DATA.value.id, {
                size: '400_400',
                target: 'profile_picture',
              })
            : getInfProfilePictureForId(INFLUENCER_ID.value, {
                size: '400_400',
                target: 'profile_picture',
              })) || '',
      },
      submissions,
      answer_submissions: submissions - USER_SUBMISSIONS.value[0],
      first_payment_date: USER_FIRST_PAYMENT_DATE.value,
      companies: companies.value as [any],
    }
  }

  onMounted(() => {
    // hide intercom on widgets
    if (window.location.pathname.match('/influencer/widget/.') !== null) return

    Intercom({
      app_id: 'mymph3uf',
      custom_launcher_selector: '#custom_intercom_button',
      ...getIntercomSettings(),
    })
  })

  onUnmounted(() => {
    shutdownIntercom()
  })
}
